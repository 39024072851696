import classes from './SensorIcon.module.css';

import { Box, type BoxProps, ThemeIcon, Tooltip } from '@mantine/core';
import { IconEye, IconEyeOff } from '@tabler/icons-react';
import cx from 'clsx';

interface SensorIconProps extends BoxProps {
  sensorEnabled: boolean;
}

export function SensorIcon({ sensorEnabled, className, ...others }: SensorIconProps) {
  return (
    <Box className={cx(classes.root, className)} {...others}>
      {sensorEnabled ?
      <Tooltip
        label={
        <div>
              <strong>This blockchain is being monitored by sensors.</strong>
              <br />
              <span>This makes stake concentration data and related scores much more precise.</span>
            </div>}

        withinPortal
        multiline
        classNames={{ tooltip: classes.tooltip }}>

          <ThemeIcon variant="light" radius="xl" className={classes['theme-icon']}>
            <div className={classes.pulse} />
            <IconEye size="1rem" />
          </ThemeIcon>
        </Tooltip> :

      <Tooltip
        label={
        <div>
              <strong>Monitoring by sensors is unavailable.</strong>
              <br />
              <span>
                This may result in skewed stake concentration data and related scores, as we are unable to match some
                nodes to validators.
              </span>
            </div>}

        withinPortal
        multiline
        classNames={{ tooltip: classes.tooltip }}>

          <ThemeIcon variant="light" color="gray" radius="xl" className={classes['theme-icon']}>
            <IconEyeOff size="1rem" />
          </ThemeIcon>
        </Tooltip>}

    </Box>);

}